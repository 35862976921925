import React, { useState, useEffect, useCallback } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { formatNumberWithOutDecimal } from '../utils/utils';

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: '#000000', color: '#01FF85', padding: '10px', borderRadius: '5px', border: "2px solid #494949" }}>
                <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>{`Years Staked: ${payload[0].payload.range}`}</p>
                <div style={{ color: 'white' }}>
                    <p style={{ margin: '3px 0' }}>{`Number of Stakers: ${formatNumberWithOutDecimal(payload[0].value)}`}</p>
                    <p style={{ margin: '3px 0' }}>{`Percentage: ${payload[0].payload.percentage}%`}</p>
                </div>
            </div>
        );
    }
    return null;
};

const CustomLegend = ({ payload, activeIndex, onLegendClick }) => {
    if (!payload || !Array.isArray(payload) || payload.length === 0) {
        return null;
    }
    
    return (
        <ul style={{ listStyle: 'none', padding: 0, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {payload.map((entry, index) => {
                if (!entry || typeof entry !== 'object') return null;
                const value = entry.value || '';
                const color = entry.color || '#000';
                const percentage = entry.payload && entry.payload.percentage ? entry.payload.percentage : '';
                
                return (
                    <li 
                        key={`item-${index}`} 
                        style={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            marginRight: '10px', 
                            marginBottom: '5px',
                            opacity: activeIndex === null || activeIndex === index ? 1 : 0.3,
                            cursor: 'pointer',
                            fontSize: '12px'
                        }}
                        onClick={() => onLegendClick(index)}
                    >
                        <svg height="8" width="8" style={{ marginRight: '3px' }}>
                            <circle cx="4" cy="4" r="4" fill={color} />
                        </svg>
                        <span style={{ color: '#FFFFFF' }}>{`${value}${percentage ? `: ${percentage}%` : ''}`}</span>
                    </li>
                );
            })}
        </ul>
    );
};

const StakingDistributionChart = ({ data }) => {
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [activeIndex, setActiveIndex] = useState(null);

    const COLORS = ['#23DC8E', '#179C65', '#106F48', '#FDB366', '#0047CA', '#88018B', '#FFD700'];

    useEffect(() => {
        const processData = () => {
            try {
                const processedData = data.ranges.map((range, index) => ({
                    range: range[1] === Infinity || range[1] === null ? `${range[0]}+` : `${range[0]}-${range[1]}`,
                    frequency: data.frequencies[index]
                }));
                const total = processedData.reduce((sum, item) => sum + item.frequency, 0);
                const finalData = processedData.map(item => ({
                    ...item,
                    percentage: ((item.frequency / total) * 100).toFixed(2)
                }));
                setChartData(finalData);
                setIsLoading(false);
            } catch (e) {
                setError(e.message);
                setIsLoading(false);
            }
        };

        processData();

        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [data]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.recharts-wrapper') && !event.target.closest('.legend-item')) {
                setActiveIndex(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handlePieClick = useCallback((_, index) => {
        setActiveIndex(prevIndex => prevIndex === index ? null : index);
    }, []);

    const handleLegendClick = useCallback((index) => {
        setActiveIndex(prevIndex => prevIndex === index ? null : index);
    }, []);

    if (isLoading) return <div style={{ color: '#FFFFFF' }}>Loading...</div>;
    if (error) return <div style={{ color: '#FF0000' }}>Error: {error}</div>;

    const chart = (
        <ResponsiveContainer width="100%" height={400}>
            <PieChart>
                <Pie
                    data={chartData}
                    dataKey="frequency"
                    nameKey="range"
                    cx="50%"
                    cy="50%"
                    outerRadius="90%"
                    innerRadius="60%"
                    fill="#8884d8"
                    onClick={handlePieClick}
                    label={false}
                    labelLine={false}
                >
                    {chartData.map((entry, index) => (
                        <Cell 
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                            stroke="none"
                            opacity={activeIndex === null || activeIndex === index ? 1 : 0.3}
                            style={{
                                outline: 'none',
                                transition: 'transform 0.2s ease-in-out'
                            }}
                        />
                    ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend 
                    content={<CustomLegend activeIndex={activeIndex} onLegendClick={handleLegendClick} />}
                    layout="horizontal"
                    align="center"
                    verticalAlign="bottom"
                />
            </PieChart>
        </ResponsiveContainer>
    );

    return (
        <>
            <h2 style={{ fontSize: '24px', margin: '10px 0', textAlign: 'center', color: '#FFFFFF' }}>
                Stake Time Distribution For Stakers
            </h2>
            {chart}
            <div style={{ textAlign: 'center', fontStyle: 'italic', marginTop: '10px', color: '#FFFFFF', fontSize: '14px' }}>
                Distribution of Stakers by Years Staked
            </div>
        </>
    );
};

export default StakingDistributionChart;