import React, { useState, useEffect, useCallback } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { formatNumberWithOutDecimal } from '../utils/utils';
import api_url from './../../config/api_url.json';

const base_api_url = api_url.base_api_url;

const formatRange = (range) => {
    const [min, max] = range.split('-').map(num => Number(num));
    const formatNumber = (num) => num >= 1000 ? `${(num / 1000).toFixed(0)}K` : num;
    return !max || max === Infinity ? `${formatNumber(min)}+` : `${formatNumber(min)} - ${formatNumber(max)}`;
};

const processData = (data) => {
    const processedData = Object.entries(data.range_counts).map(([range, count]) => ({
        range: formatRange(range),
        count
    }));
    const total = processedData.reduce((sum, item) => sum + item.count, 0);
    return processedData.map(item => ({
        ...item,
        percentage: ((item.count / total) * 100).toFixed(2)
    }));
};

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: '#000000', color: '#01FF85', padding: '10px', borderRadius: '5px', border: "2px solid #494949" }}>
                <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>{`Range: ${payload[0].payload.range}`}</p>
                <div style={{ color: 'white' }}>
                    <p style={{ margin: '3px 0' }}>{`Number of Holders: ${formatNumberWithOutDecimal(payload[0].value)}`}</p>
                    <p style={{ margin: '3px 0' }}>{`Percentage: ${payload[0].payload.percentage}%`}</p>
                </div>
            </div>
        );
    }
    return null;
};

const CustomLegend = ({ payload, activeIndex, onLegendClick }) => {
    if (!payload || !Array.isArray(payload) || payload.length === 0) {
        return null;
    }
    
    return (
        <ul style={{ listStyle: 'none', padding: 0, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {payload.map((entry, index) => {
                if (!entry || typeof entry !== 'object') return null;
                const value = entry.value || '';
                const color = entry.color || '#000';
                const percentage = entry.payload && entry.payload.percentage ? entry.payload.percentage : '';
                
                return (
                    <li 
                        key={`item-${index}`} 
                        style={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            marginRight: '10px', 
                            marginBottom: '5px',
                            opacity: activeIndex === null || activeIndex === index ? 1 : 0.3,
                            cursor: 'pointer',
                            fontSize: '12px'
                        }}
                        onClick={() => onLegendClick(index)}
                    >
                        <svg height="8" width="8" style={{ marginRight: '3px' }}>
                            <circle cx="4" cy="4" r="4" fill={color} />
                        </svg>
                        <span style={{ color: '#FFFFFF' }}>{`${value}${percentage ? `: ${percentage}%` : ''}`}</span>
                    </li>
                );
            })}
        </ul>
    );
};


const MORHoldersDistribution = () => {
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [activeIndex, setActiveIndex] = useState(null);

    const COLORS = ['#23DC8E', '#179C65', '#106F48', '#FDB366', '#0047CA', '#88018B', '#FFD700'];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${base_api_url}/mor_holders_by_range`);
                const data = await response.json();
                setChartData(processData(data));
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
            }
        };
        fetchData();

        const handleResize = () => {
            const newIsMobile = window.innerWidth <= 768;
            setIsMobile(newIsMobile);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.recharts-wrapper') && !event.target.closest('.legend-item')) {
                setActiveIndex(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handlePieClick = useCallback((_, index) => {
        setActiveIndex(prevIndex => prevIndex === index ? null : index);
    }, []);

    const handleLegendClick = useCallback((index) => {
        setActiveIndex(prevIndex => prevIndex === index ? null : index);
    }, []);

    if (isLoading) return <div style={{ color: '#FFFFFF' }}>Loading...</div>;
    if (error) return <div style={{ color: '#FF0000' }}>Error: {error}</div>;

    const chart = (
        <ResponsiveContainer width="100%" height={isMobile ? 250 : 400}>
            <PieChart>
                <Pie
                    data={chartData}
                    dataKey="count"
                    nameKey="range"
                    cx="50%"
                    cy="50%"
                    outerRadius={isMobile ? "80%" : "90%"}
                    innerRadius={isMobile ? "50%" : "60%"}
                    fill="#8884d8"
                    onClick={handlePieClick}
                    label={false}
                    labelLine={false}
                >
                    {chartData.map((entry, index) => (
                        <Cell 
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                            stroke="none"
                            opacity={activeIndex === null || activeIndex === index ? 1 : 0.3}
                            style={{
                                outline: 'none',
                                transition: 'transform 0.2s ease-in-out'
                            }}
                        />
                    ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                {!isMobile && (
                    <Legend 
                        content={<CustomLegend activeIndex={activeIndex} onLegendClick={handleLegendClick} />}
                        layout="horizontal"
                        align="center"
                        verticalAlign="bottom"
                    />
                )}
            </PieChart>
        </ResponsiveContainer>
    );

    return (
        <div className="supply_chart_background" style={{ padding: '10px', maxWidth: '100%', backgroundColor: '#1E1E1E', borderRadius: '8px' }}>
            <h2 className="chartheading" style={{ fontSize: isMobile ? '18px' : '24px', margin: '10px 0', textAlign: 'center', color: '#FFFFFF' }}>
                Number of MOR Holders by Range
            </h2>
            <p className="chart-note" style={{ textAlign: 'center', fontSize: isMobile ? '12px' : '14px', color: '#a0a0a0' }}>
                Addresses which hold $MOR on either ETH, ARB or BASE
            </p>
            {chart}
            {isMobile && (
                <div style={{ marginTop: '10px' }}>
                    <CustomLegend 
                        payload={chartData.map((entry, index) => ({
                            value: entry.range,
                            color: COLORS[index % COLORS.length],
                            payload: entry
                        }))} 
                        activeIndex={activeIndex} 
                        onLegendClick={handleLegendClick} 
                    />
                </div>
            )}
            <div style={{ textAlign: 'center', fontStyle: 'italic', marginTop: '10px', color: '#FFFFFF', fontSize: isMobile ? '12px' : '14px' }}>
                Distribution of MOR holders by Amount of MOR Held
            </div>
        </div>
    );
};

export default MORHoldersDistribution;